
import React, { ForwardedRef, PropsWithoutRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import styles from './ProductList.module.scss'
import { IProductItem } from './IProductItem'
import { moneyMask } from 'services/utils/Money'
import { AlertModal } from '../../alertModal/AlertModal'
import { Checkbox, Icon, IconButton, Switch, Tooltip } from '@mui/material'
import { EnumProductType } from '../../complement/interfaces/export enum EnumProductType {'
import { useParams } from 'react-router-dom'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useAuth } from 'modules/auth/presentation/context/AuthContext'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import UseDimension from 'components/dimension/UseDimension'
import { DeleteOutlined, EditOutlined, LockOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons'
import { ContentCopyOutlined } from '@mui/icons-material'

export interface IProductListItemProps {
    //propertys
    productItem: IProductItem,
    checked?: boolean;
    hideInvoiceData?: boolean
    hidePrinter?: boolean
    showDeleteProduct?: boolean
    onCheckProduct?: (product: IProductItem, value: boolean) => void
    onClickProduct?: (product: IProductItem) => void
    onClickEditProduct?: (product: IProductItem, catalogId: string) => void
    onClickRemoveProduct?: (product: IProductItem) => void
    onClickDeleteProduct?: (product: IProductItem) => void
    onChangeEnabledProduct?: (product: IProductItem, value: boolean) => void
    onChangeFeaturedProduct?: (product: IProductItem, value: boolean) => void
    onClickCopyProduct?: (product: IProductItem) => void
    onClickProductAccess?: (product: IProductItem) => void
    onChangeHide?: (product: IProductItem, value: boolean) => void
    currentCatalogId?: string
    dragHandleProps?: DraggableProvidedDragHandleProps | undefined;
    isDragInDropDisabled?: boolean
}
export interface ProductItemRef {
    checkProduct: (value: boolean) => void
}
const ProductListItem = ({
    productItem,
    onClickProduct,
    onClickEditProduct,
    onClickRemoveProduct,
    onChangeEnabledProduct,
    onChangeFeaturedProduct,
    onClickDeleteProduct,
    onCheckProduct,
    onClickCopyProduct,
    checked,
    hideInvoiceData,
    hidePrinter,
    showDeleteProduct,
    onClickProductAccess,
    onChangeHide,
    currentCatalogId,
    dragHandleProps,
    isDragInDropDisabled,
}: PropsWithoutRef<IProductListItemProps>, ref: ForwardedRef<ProductItemRef>) => {

    const [isFeatured, setFeatured] = useState(productItem.featured);
    const [isEnabled, setIsEnabled] = useState(productItem.enabled);
    const [isChecked, setIsChecked] = useState(checked ?? false);
    const [modalRemove, setModalRemove] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [isHiden, setIsHiden] = useState<boolean>();
    const { toast } = useUi();
    const { hasAccessRole } = useAuth();
    const { isMobile } = UseDimension();

    const { catalogId } = useParams<{ catalogId: string }>();

    useEffect(() => {
        if (checked !== undefined) {
            setIsChecked(checked)
        }
    }, [checked])

    const onClickProductHandle = useCallback(() => {
        onClickProduct?.(productItem)
    }, [onClickProduct, productItem])

    const onClickEditProductHandle = useCallback(() => {
        onClickEditProduct?.(productItem, catalogId)
    }, [catalogId, onClickEditProduct, productItem])

    const onClickCopyProductHandle = useCallback(() => {
        onClickCopyProduct?.(productItem)
    }, [onClickCopyProduct, productItem])

    const onClickRemoveProductHandle = useCallback(() => {
        onClickRemoveProduct?.(productItem)
    }, [onClickRemoveProduct, productItem])

    const onClickDeleteProductHandle = useCallback(() => {
        onClickDeleteProduct?.(productItem)
    }, [onClickDeleteProduct, productItem])

    const onOpenRemoveProductHandle = useCallback(() => {
        setModalRemove(true)
    }, [])
    const onOpenDeleteProductHandle = useCallback(() => {
        setModalDelete(true)
    }, [])

    const onClickProductAccessHandle = useCallback(() => {
        onClickProductAccess?.(productItem);
    }, [onClickProductAccess, productItem])

    const onChangeEnabledProductHandle = useCallback((_, value: boolean) => {
        onChangeEnabledProduct?.(productItem, value);
        setIsEnabled(value);
    }, [onChangeEnabledProduct, productItem]);


    const onChangeHideHandle = useCallback((_, value: boolean) => {
        onChangeHide?.(productItem, !value);
        setIsHiden(!value);
    }, [onChangeHide, productItem])

    const onChangeFeaturedProductHandle = useCallback((_isFeatured) => {
        onChangeFeaturedProduct?.(productItem, _isFeatured)
        setFeatured?.(_isFeatured)
    }, [onChangeFeaturedProduct, productItem])

    const onCheckedProductHandel = useCallback(
        (checked: boolean) => {
            setIsChecked(checked);
            onCheckProduct?.(productItem, checked);
        },
        [onCheckProduct, productItem],
    )

    useEffect(() => {
        setIsChecked(!!productItem.checked)
    }, [productItem.checked])

    useImperativeHandle(
        ref,
        () => ({
            checkProduct: onCheckedProductHandel
        }),
        [onCheckedProductHandel],
    )

    const imageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const _isHiden = productItem.catalogs?.find(item => item.id === currentCatalogId)?.isHiddenProduct;
        setIsHiden(_isHiden);
    }, [currentCatalogId, productItem.catalogs])

    const canDuplicateProduct = useMemo(() =>
        productItem.type !== EnumProductType.ATIVACAO_CASHLESS_PRE_PAGO &&
        productItem.type !== EnumProductType.DESATIVACAO_CASHLESS_PRE_PAGO &&
        productItem.type !== EnumProductType.ATIVACAO_CASHLESS_POS_PAGO &&
        productItem.type !== EnumProductType.DESATIVACAO_CASHLESS_POS_PAGO &&
        productItem.type !== EnumProductType.ATIVACAO_POS_PAGO_COMANDA &&
        productItem.type !== EnumProductType.DESATIVACAO_POS_PAGO_COMANDA &&
        productItem.type !== EnumProductType.TAXA_DE_SERVICO &&
        productItem.type !== EnumProductType.AJUSTE
        , [productItem.type])

    const canRemoveProduct = useMemo(() =>
        (productItem.type === EnumProductType.PRODUTO ||
            productItem.type === EnumProductType.VARIAVEL ||
            productItem.type === EnumProductType.KILO ||
            productItem.type === EnumProductType.INGRESSO_COM_NOME_NA_LISTA ||
            productItem.type === EnumProductType.DOACAO ||
            productItem.type === EnumProductType.INGRESSO_AVULSO) &&
            
        productItem.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')?.toLocaleLowerCase() !== 'valor variavel',
        [productItem.name, productItem.type]
    )
    const copyText = () => {
        navigator.clipboard.writeText(productItem.id);
        toast('Texto copiado: ' + productItem.id, "success");
    };

    return (
        <div ref={imageRef} id={styles.ProductListItem} onClick={onClickProductHandle}>
            <div className={styles.checkButton} >
                {!isDragInDropDisabled && <div className={styles.dragIcon} {...dragHandleProps} >
                    <Icon className={styles.titleIcon} >drag_indicator</Icon>
                </div>}
                {onCheckProduct && <Checkbox
                    style={{ height: 40 }}
                    checked={isChecked}
                    onChange={(_, _checked) => {
                        onCheckedProductHandel?.(_checked);
                    }}
                />}
                <img
                    onError={({ currentTarget }) => {
                        currentTarget.src = "/assets/icon/placeholder.svg";
                    }}
                    alt='Imagem produto'
                    src={productItem.image && productItem.image !== "" ? 
                        productItem.image : 
                            (productItem.thumbnailImageUrl && productItem.thumbnailImageUrl !== "" ? 
                            productItem.thumbnailImageUrl :
                                productItem.imageUrl && productItem.imageUrl !== "" ? productItem.imageUrl : "/assets/icon/placeholder.svg") + "?v=" + Math.random()}
                    className={styles.image}
                />
                {isMobile && (
                    <>
                        <div className={styles.descriptions} style={{ marginBottom: "auto" }}>
                            <div className={styles.name} >
                                {productItem.name}
                            </div>
                            {(hasAccessRole('Admin') || hasAccessRole('SuporteMeep')) && productItem.id &&
                                <div className={styles.id}>
                                    {productItem.id}
                                    <Icon onClick={() => copyText()}>content_copy</Icon>
                                </div>
                            }

                            <div className={styles.description} >
                                {productItem.description}
                            </div>
                        </div>
                        <div className={styles.value} style={{ marginBottom: "auto" }}>
                            {moneyMask(productItem.value.toFixed(2))}
                        </div>
                    </>
                )}
            </div>
            <>
                {!isMobile && (
                    <>
                        <div className={styles.descriptions} >
                            <div className={styles.name} >
                                {productItem.name}
                            </div>
                            {(hasAccessRole('Admin') || hasAccessRole('SuporteMeep')) && productItem.id &&
                                <div className={styles.id}>
                                    {productItem.id}
                                    <Icon onClick={() => copyText()}>content_copy</Icon>
                                </div>
                            }

                            <div className={styles.description} >
                                {productItem.description}
                            </div>
                        </div>
                        <div className={styles.value} >
                            {moneyMask(productItem.value.toFixed(2))}
                        </div>
                    </>
                )}
                <div className={styles.fiscalAndPrinter}>
                    {!hideInvoiceData && <div className={styles.fiscal}>
                        {<div className={styles.item}>
                            <label >NCM: </label>
                            <div>{!!productItem.ncm ? productItem.ncm : '--'}</div>
                        </div>}
                        {<div className={styles.item}>
                            <label >CEST: </label>
                            <div>{!!productItem.cest ? productItem.cest : '--'}</div>
                        </div>}
                        {<div className={styles.item}>
                            <label >CFOP: </label>
                            <div>{!!productItem.cfop ? productItem.cfop : '--'}</div>
                        </div>}
                    </div>}
                    {!hidePrinter && <div className={styles.printer}>
                        {<>
                            <Icon>printer</Icon>
                            {productItem?.defaultPrinter?.name ? productItem?.defaultPrinter?.name : '--'}</>
                        }
                    </div>}
                </div>

                <div className={styles.action} >
                    {
                        canDuplicateProduct &&
                        onChangeFeaturedProduct &&
                        <div className={styles.featured}>
                            <Tooltip title={'Destacar produto'}>
                                <IconButton onClick={() => onChangeFeaturedProductHandle(!isFeatured)} >
                                    <Icon className={styles.icon}>{isFeatured ? "grade" : "star_outline"}</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                    {
                        canDuplicateProduct &&
                        onChangeEnabledProduct &&
                        (!currentCatalogId || currentCatalogId === 'undefined') &&
                        <div className={styles.featured}>
                            <Switch defaultChecked={isEnabled} color='primary' onChange={onChangeEnabledProductHandle}></Switch>
                        </div>
                    }
                    {
                        canDuplicateProduct &&
                        onChangeHide &&
                        currentCatalogId &&
                        isHiden !== undefined &&
                        <Tooltip title={'Visibilidade do produto'}>
                            <div className={styles.featured} >
                                <IconButton onClick={() => onChangeHideHandle(null, isHiden)}>
                                    {isHiden ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                </IconButton>
                            </div>
                        </Tooltip>
                    }

                    {onClickEditProduct && (
                        <Tooltip title={'Editar produto'}><IconButton onClick={onClickEditProductHandle}><EditOutlined /></IconButton></Tooltip>
                    )}
                    {onClickCopyProduct && canDuplicateProduct && (
                        <Tooltip title={'Copiar produto'}><IconButton onClick={onClickCopyProductHandle}><ContentCopyOutlined /></IconButton></Tooltip>
                    )}
                    {onClickRemoveProduct && canRemoveProduct && productItem.catalogs?.length && productItem.catalogs?.length > 1 
                        ? (
                            <Tooltip title={'Remover produto da lista'}><IconButton onClick={onOpenRemoveProductHandle}><DeleteOutlined /></IconButton></Tooltip>
                        ) : (onClickDeleteProduct && canRemoveProduct && 
                            <Tooltip title={'Apagar produto '}><IconButton onClick={onOpenDeleteProductHandle}>{showDeleteProduct ? <DeleteOutlined /> : <DeleteOutlined />}</IconButton></Tooltip>
                        )
                    }
                    {(onClickProductAccess && <IconButton onClick={onClickProductAccessHandle}>{<LockOutlined />}</IconButton>)}
                </div>
            </>
            
            <AlertModal
                open={modalRemove}
                title={<h2>Remover <b>produto do cardápio</b></h2>}
                text={<div>Deseja remover <b>{productItem.name}</b></div>}
                subText={<span><b>Não se preocupe:</b> essa ação irá remover seu produto apenas deste cardápio e ele permanecerá disponível nos demais. Caso queira excluir o produto definitivamente, acesse a guia <b style={{ color: "rgba(50, 0, 142, 1)" }}>Todos os Produtos.</b></span>}
                onClose={() => setModalRemove(false)}
                confirmLabel={'Remover'}
                cancelLabel={'Voltar'}
                onConfirm={onClickRemoveProductHandle}
                alertImg="/assets/icon/alert-negative.svg"
            />
            {
                productItem.isProductComposition ? 
                <AlertModal
                    open={modalDelete}
                    title={<h2>Excluir <b>produto</b></h2>}
                    text={<div>Este produto está como adicional em outros itens do seu cardápio. Deseja realmente excluir?</div>}
                    onClose={() => setModalDelete(false)}
                    confirmLabel={'Excluir'}
                    cancelLabel={'Voltar'}
                    onConfirm={onClickDeleteProductHandle}
                    alertImg="/assets/icon/alert-negative.svg"
                />
                :
                <AlertModal
                    open={modalDelete}
                    title={<h2>Excluir <b>produto</b></h2>}
                    text={<div>Deseja realmente excluir <b>{productItem.name}</b>?</div>}
                    subText={<span><b>Atenção:</b> ao confirmar a exclusão permanente do produto, ele não estará mais disponível em nenhum cardápio.</span>}
                    onClose={() => setModalDelete(false)}
                    confirmLabel={'Excluir'}
                    cancelLabel={'Voltar'}
                    onConfirm={onClickDeleteProductHandle}
                    alertImg="/assets/icon/alert-trash.svg"
                />
            }
        </div>
    )
}
export default forwardRef<ProductItemRef, IProductListItemProps>(ProductListItem)