import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../saleForDevice/presentation/SaleForDevicePage.module.scss";
import Sidesheet from "components/sidesheet/Sidesheet";
import CashiersSidesheet from "modules/saleForDevice/presentation/components/cashiersSidesheet/CashiersSidesheet";
import ForProductFilter from "./components/forProductFilter/ForProductFilter";
import { IPostSaleForProductParams } from "../domain/dto/IPostSaleForProductParams";
import { UseSaleForDevicePage } from "modules/saleForDevice/presentation/hooks/UseSaleForDevicePage";
import { Info } from "@material-ui/icons";
import { UseSaleForProductPage } from "./hooks/UseSaleForProductPage";
import { Skeleton } from "@mui/material";
import UseDimension from "components/dimension/UseDimension";
import ForProductHeader from "./components/forProductHeader/ForProductHeader";
import ForProductItem from "./components/forProductItem.tsx/ForProductItem";
import ForProductItemMobile from "./components/forProductItemMobile/ForProductItemMobile";
import ForProductTotalItem from "./components/forProductTotalItem.tsx/ForProductTotalItem";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import * as XLSX from "xlsx";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

const SaleForProductPage = () => {
  const [params, setParams] = useState({} as IPostSaleForProductParams);
  const [openSidesheet, setOpenSidesheet] = useState(false);
  const [step, setStep] = useState(1);
  const [optionDownload, setOptionDownload] = useState("xlsx");
  const [openSidesheetDownload, setOpenSidesheetDownload] = useState<
    null | string
  >(null);

  const {
    cashiersByPeriod,
    getCashiersByPeriod,
    isLoading: isLoadingCashiers,
  } = UseSaleForDevicePage();
  const { reportData, postSaleForProduct, isLoading } = UseSaleForProductPage();
  const { dimensions } = UseDimension();
  const { showLoading, hideLoading } = useUi();

  useEffect(() => {
    if (params.sessaoId || (params.dataInicio && params.dataFim)) {
      postSaleForProduct(params);
    }
  }, [params, postSaleForProduct]);

  const handleOpenSidesheet = useCallback(
    (startDate: Date, endDate: Date) => {
      setOpenSidesheet(true);
      getCashiersByPeriod(startDate, endDate);
    },
    [getCashiersByPeriod]
  );

  const skeletons = Array.from({ length: 4 }, (_, index) => (
    <Skeleton
      key={index}
      variant="text"
      sx={{ fontSize: "40px", marginBottom: "10px" }}
    />
  ));

  const pdfRef = useRef<HTMLDivElement>(null);

  const generatePDF = useCallback(async () => {
    if (!pdfRef.current) return;

    const pdf = new jsPDF({ orientation: "landscape", format: [800, 600] });
    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = 1260;
    const padding = 20;

    const elements = pdfRef.current.querySelectorAll(".div-to-capture");
    const divs = Array.from(elements).filter(
      (element) => element instanceof HTMLElement
    );

    let currentHeight = 0;
    let divsByPage: Element[] = [];
    let pages: Element[] = [];

    for (const div of divs) {
      const { height } = div.getBoundingClientRect();

      if (currentHeight + height < pageHeight) {
        currentHeight += height;
        divsByPage.push(div);
      } else {
        const uniqueDiv = document.createElement("div");

        divsByPage.forEach((element) => {
          uniqueDiv.appendChild(element.cloneNode(true));
        });

        pages.push(uniqueDiv);
        currentHeight = height;
        divsByPage = [div];
      }
    }

    if (divsByPage.length) {
      const uniqueDiv = document.createElement("div");

      divsByPage.forEach((element) => {
        uniqueDiv.appendChild(element.cloneNode(true));
      });

      pages.push(uniqueDiv);
    }

    for (let i = 0; i < pages.length; i++) {
      const tempDiv = pages[i] as HTMLElement;
      tempDiv.style.width = "1915px";

      if (i === pages.length - 1) {
        tempDiv.style.height = "100%";
      }

      document.body.appendChild(tempDiv);

      const canvas = await html2canvas(tempDiv);
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(
        imgData,
        "PNG",
        padding,
        padding,
        imgWidth - 2 * padding,
        imgHeight - 2 * padding
      );

      if (i !== pages.length - 1) {
        pdf.addPage();
      }

      document.body.removeChild(tempDiv);
    }

    const fileName = "Relatório_venda_por_produtos.pdf";
      pdf.save(fileName);
      hideLoading()
      setStep(2)
    }, [hideLoading]);

  const downloadXlsx = () => {
    if (reportData) {
      const produtosData = reportData.produtos.map((item) => ({
        Categoria: item.categoriaNome,
        Produto: item.produtoNome,
        Quantidade: item.quantidade?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Unidade: item.unidade,
        Cashless: item.cashless?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Debito: item.debito?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Credito: item.credito?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Dinheiro: item.dinheiro?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Voucher: item.voucher?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Divisao: item.divisao?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Outros: item.outros?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Desconto: item.desconto?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Valor: item.valor?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
      }));

      produtosData.push({
        Categoria: "Total Geral:",
        Produto: "",
        Quantidade: reportData.totalQuantidade?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Unidade: reportData.totalUnidade,
        Cashless: reportData.totalCashless?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Debito: reportData.totalDebito?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Credito: reportData.totalCredito?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Dinheiro: reportData.totalDinheiro?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Voucher: reportData.totalVoucher?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Divisao: reportData.totalDivisao?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Outros: reportData.totalOutros?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Desconto: reportData.totalDesconto?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
        Valor: reportData.totalGeral?.toLocaleString("pt-BR", { minimumFractionDigits: 2 }),
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(produtosData);
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Relatório venda por produtos"
      );

      const blob = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      const blobUrl = URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "Relatório_venda_por_produtos.xlsx");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
      hideLoading()
      setStep(2);
    }
  };

  const onDownload = () => {
    showLoading();
    if (optionDownload === "xlsx") {
      downloadXlsx();
    } else generatePDF();
  };

  return (
    <div id={styles.SaleForProductPage}>
      <ForProductFilter
        setParams={setParams}
        handleOpenSidesheet={handleOpenSidesheet}
        setOpenSidesheetDownload={setOpenSidesheetDownload}
      />
      {isLoading ? (
        <div style={{ marginTop: "1rem" }}>{skeletons}</div>
      ) : (
        !!reportData?.produtos.length && (
          <div ref={pdfRef}>
            <div
              className={styles.table}
              style={{
                overflowX:
                  dimensions.width < 1428 && dimensions.width > 650
                    ? "scroll"
                    : "hidden",
              }}
            >
              {dimensions.width > 650 && (
                <div className="div-to-capture">
                  <ForProductHeader params={params} setParams={setParams} />
                </div>
              )}
              {reportData.produtos.map((product) => (
                <>
                  {dimensions.width > 650 ? (
                    <div className="div-to-capture">
                      <ForProductItem item={product} key={product.produtoId} />
                    </div>
                  ) : (
                    <div className="div-to-capture">
                      <ForProductItemMobile
                        item={product}
                        key={product.produtoId}
                      />
                    </div>
                  )}
                </>
              ))}
              <div className="div-to-capture">
                <ForProductTotalItem item={reportData} />
              </div>
            </div>
            <div className="div-to-capture">
              <span>
                <Info />
                Observação:
              </span>
              <p>
                1 - <b>Valor total</b> poderá ocorrer diferenças na comparação
                com outros relatórios devido o suprimento de recargas.
              </p>
              <p>
                2 - A <b>coluna cashless</b> não está somando o valor de
                desconto.
              </p>
              <p>
              3 - A coluna <b>"Quantidade"</b> é utilizada para identificar o número de unidades para produtos vendidos por unidade, ou o peso em quilogramas (KG) para produtos pesados.
              </p>
              <p>4 - A coluna <b>"Unidade"</b> é destinada a identificar o número de vendas de produto (unidade).</p>
            </div>
          </div>
        )
      )}
      {reportData && !reportData.totalGeral && !reportData?.produtos.length && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img src="/assets/img/empty-box.png" alt="" />
          <span>
            <b>Não há dados</b> para exibição.
          </span>
        </div>
      )}

      {openSidesheet && (
        <Sidesheet
          open={openSidesheet}
          onClose={() => setOpenSidesheet(false)}
          title={
            <h2>
              Caixas do <b>período</b>
            </h2>
          }
          content={
            <CashiersSidesheet
              cashiersByPeriod={cashiersByPeriod}
              isLoading={isLoadingCashiers}
            />
          }
          continueButton="Fechar"
          handleContinueButton={() => setOpenSidesheet(false)}
          currentStep={1}
          totalSteps={1}
          notTotalHeight
        />
      )}
      <Sidesheet
        open={openSidesheetDownload}
        onClose={() => {
          setOpenSidesheetDownload(null);
          setStep(1);
        }}
        title={<h1>Exportar</h1>}
        content={
          <div>
            <p>Selecione abaixo uma opção para exportar o relatório</p>
            <RadioGroup
              onChange={(_, value) => setOptionDownload(value)}
              value={optionDownload}
              aria-labelledby="demo-radio-buttons-group-label"
            >
              <div className={styles.radioItem}>
                <FormControlLabel
                  value={`xlsx`}
                  control={<Radio />}
                  label="XLSX"
                />
              </div>
              <div className={styles.radioItem}>
                <FormControlLabel
                  value={`pdf`}
                  control={<Radio />}
                  label="PDF"
                />
              </div>
            </RadioGroup>
          </div>
        }
        cancelButton
        continueButton={"Exportar"}
        handleContinueButton={onDownload}
        currentStep={step}
        totalSteps={2}
        feedback={
          <SidesheetFeedback text="Relatório exportado com sucesso!" success />
        }
      />
    </div>
  );
};

export default SaleForProductPage;
