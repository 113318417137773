import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './ProductForm.module.scss'
import { ICatalogSelect, ICategorySelect, IEventSelect, IPrinterSelect, IProductFormValue, IProductSelect, IStoreSelect, ProductFormValueDefaut } from './IProducFormValuet'
import { Button, MenuItem } from '@material-ui/core';
import { EnumGender } from 'modules/crm/presentation/intefaces/GenderEnum';
import { SwitchLabel } from '../ui/SwitchLabel';
import { Separator } from '../ui/Separator';
import { SubTitle } from '../ui/SubTitle';
import { ContainerLabel } from '../ui/ContainerLabel';
import { EnumProductType } from 'modules/products/presentation/interfaces/IProductListData';
import { InputLabel } from '../ui/InputLabel';
import { InputMoney } from '../ui/InputMoney';
import ComplementFragment from '../../fragments/complementFragment/ComplementFragment';
import { IComplementGroupFormValue } from '../complement/complementForm/ComplementForm';
import ButtonsForm from '../ui/ButtonsForm';
import { InputImage } from '../ui/InputImage';
import ProductFormSkeleton from '../productFormSkeleton/ProductFormSkeleton';
import { ArrowBackIosRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, OpenInNewOutlined } from '@material-ui/icons';
import { Autocomplete, IconButton } from '@mui/material';
import ObservationForm, { IObservationProductFormValue } from '../observationForm/ObservationForm';
import { v4 } from 'uuid';
import InputMasked from 'components/inputMasked/InputMasked';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';
import { SystemIdentifierEnum } from 'modules/gestaoVendas/domain/enum/GetSegmentosEnum';

export interface IProductFormProps {
    //propertys
    defaultCatalogId?: string,
    defaultCategoryId?: string,
    defaultValue?: IProductFormValue,
    categories?: ICategorySelect[],
    catalogs?: ICatalogSelect[],
    printers: IPrinterSelect[],
    stores: IStoreSelect[],
    events?: IEventSelect[]
    isLoading: boolean,
    onSubmit: (values: IProductFormValue) => void,
    getComplementFromProduct?: (product: IProductSelect) => Promise<IComplementGroupFormValue[]>
    onCancel?: () => void
    disabledComplementGroup?: boolean,
    sectorPrinters?: IPrinterSelect[]
}

interface IProductError {
    category?: string,
    catalog?: string,
    name?: string,
    ncm?: string,
    cfop?: string,
    value?: string,
    observations?: string,
    complements?: string,
}

const ProductForm: FC<IProductFormProps> = ({ categories, stores, printers, events, onCancel, defaultCategoryId, defaultValue, onSubmit, catalogs, defaultCatalogId, isLoading, disabledComplementGroup, sectorPrinters }) => {
    const { localConfiguration, currentLocal } = useLocal();

    const [values, setValues] = useState<IProductFormValue>(ProductFormValueDefaut);
    const [hideAdvancedProperty, setHideAdvancedProperty] = useState(true);
    const [error, setError] = useState<IProductError>();
    const disabledForm = false;
    const width = window.innerWidth;

    useEffect(() => {
        if (defaultValue) {
            setValues({
                ...defaultValue,
                store: stores.find(item => item.id === defaultValue.storeId),
                complements: defaultValue.complements?.map(item => ({
                    complementCategory: { ...item.complementCategory, id: item.complementCategory.id ?? v4() },
                    complementProducts: item.complementProducts.map(item => ({ ...item, id: item.id ?? v4() }))
                }))
            });
        }
    }, [defaultValue, stores]);

    const version = useMemo(() => Math.random(), []);

    const isConfigProduct = useMemo(() =>
        defaultValue?.type === EnumProductType.RECARGA ||
        defaultValue?.type === EnumProductType.PROMOCAO ||
        defaultValue?.type === EnumProductType.ATIVACAO_CASHLESS_PRE_PAGO ||
        defaultValue?.type === EnumProductType.DESATIVACAO_CASHLESS_PRE_PAGO ||
        defaultValue?.type === EnumProductType.ATIVACAO_CASHLESS_POS_PAGO ||
        defaultValue?.type === EnumProductType.DESATIVACAO_CASHLESS_POS_PAGO ||
        defaultValue?.type === EnumProductType.ATIVACAO_POS_PAGO_COMANDA ||
        defaultValue?.type === EnumProductType.DESATIVACAO_POS_PAGO_COMANDA ||
        defaultValue?.type === EnumProductType.TAXA_DE_SERVICO ||
        defaultValue?.type === EnumProductType.AJUSTE ||
        defaultValue?.type === EnumProductType.TAXA_DE_ENTREGA
        , [defaultValue?.type]);

    useEffect(() => {
        setValues(prev => {
            let returnObject = { ...prev }
            if (defaultCategoryId) {
                returnObject.category = categories?.find(item => item.id === defaultCategoryId);
            }
            if (defaultCatalogId) {
                returnObject.catalogs = catalogs?.filter(item => item.id === defaultCatalogId);
            }
            return returnObject
        });

    }, [catalogs, categories, defaultCatalogId, defaultCategoryId]);

    const validate = useCallback((_values: IProductFormValue) => {
        let hasError = false;
        setError({});

        if (!_values.category && !isConfigProduct) {
            hasError = true;
            setError(prev => ({ ...prev, category: "Categoria é obrigatária" }));
        }
        if (!_values.name) {
            hasError = true;
            setError(prev => ({ ...prev, name: "Nome é obrigatário" }));
        }
        if (_values.value === null || _values.value === undefined || _values.value < 0) {
            hasError = true;
            setError(prev => ({ ...prev, value: "Insira um valor válido" }));
        }
        if (!_values.ncm && values.type !== EnumProductType.TAXA_DE_SERVICO && (localConfiguration?.gerarNFCE ?? false)) {
            hasError = true;
            setError(prev => ({ ...prev, ncm: "NCM obrigatário" }));
        }
        if (!_values.cfop && values.type !== EnumProductType.TAXA_DE_SERVICO && (localConfiguration?.gerarNFCE ?? false)) {
            hasError = true;
            setError(prev => ({ ...prev, cfop: "CFOP obrigatário" }));
        }

        if (hasError) {
            return false;
        }

        setError(undefined);
        return true
    }, [isConfigProduct, localConfiguration, values.type])

    const submitHandle = useCallback(() => {
        if (validate(values)) {
            onSubmit(values);
        }
    }, [onSubmit, validate, values])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const changeHandleSwitch = useCallback((name, ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [name]: ev.target.checked }));
    }, []);

    const changeImageHandle = useCallback((image: string) => {
        setValues((prev) => ({ ...prev, image: image }));
    }, []);

    const changeCategoryHandle = useCallback((_, value: ICategorySelect | null) => {
        value && setValues((prev) => ({ ...prev, category: value }));
    }, []);
    const changeCatalogHandle = useCallback((_, value: ICategorySelect[] | null) => {
        value && setValues((prev) => ({ ...prev, catalogs: value }));
    }, []);

    const changeObservationHandle = useCallback((value: IObservationProductFormValue[]) => {
        value && setValues((prev) => ({ ...prev, observations: value }));
    }, []);

    const onChangeGeneralObservation = useCallback((value?: boolean) => {
        setValues((prev) => ({ ...prev, productGeneralObservation: value }));
    }, []);

    const onChangeComplement = useCallback((complements: IComplementGroupFormValue[]) => {
        setValues((prev) => ({ ...prev, complements: [...complements] }));
    }, [])

    const onChangePrinter = useCallback((ev, value: IPrinterSelect | null) => {
        setValues((prev) => ({ ...prev, defaultPrinter: value }));
    }, [])
    const onChangeEvent = useCallback((ev, value: IPrinterSelect | null) => {
        setValues((prev) => ({ ...prev, event: value }));
    }, [])
    const onChangeStore = useCallback((ev, complements: IStoreSelect | null) => {
        setValues((prev) => ({ ...prev, store: complements, storeId: complements?.id }));
    }, [])


    const onChangeSectorPrinter = useCallback((ev, value: IPrinterSelect[] | null) => {
        setValues((prev) => ({ ...prev, sectorPrinterIds: value?.map(item => item.id) }));
    }, [])


    const sectorPrinterValues = useMemo(() => {
        return sectorPrinters?.filter(item => values.sectorPrinterIds?.includes(item.id)) ?? []
    }, [sectorPrinters, values.sectorPrinterIds])

    return (
        isLoading ? <ProductFormSkeleton /> :
            <div id={styles.ProductForm} >
                <div className={styles.container} >
                    <div onClick={onCancel} className={styles.clickBack}>
                        <ArrowBackIosRounded />
                        <p>Voltar</p>
                    </div>
                    <div className={styles.column}>
                        <SubTitle step={1}>Informe os <b>detalhes:</b></SubTitle>
                        <div className={`${styles.row} ${styles.rowMobile}`} >
                            {defaultValue?.type !== EnumProductType.TAXA_DE_SERVICO && <div className={styles.imageInput} >
                                <InputImage size={180} value={values.image ?? (values.imageUrl + "?v=" + version)} onChange={changeImageHandle} />
                                <span>
                                    Formatos: JPEG e PNG<br />
                                    Resolução ideal: 400x400
                                </span>
                            </div>}
                            <div className={`${styles.row} ${styles.rowMobile}`} >
                                <div className={styles.column} >
                                    <InputLabel
                                        disabled={disabledForm || isConfigProduct}
                                        error={!!error?.name}
                                        helperText={error?.name}
                                        onChange={changeHandle}
                                        name="name"
                                        value={values.name}
                                        label="Nome do produto"
                                        required />
                                    <InputMoney
                                        disabled={disabledForm || defaultValue?.type === EnumProductType.TAXA_DE_SERVICO}
                                        error={!!error?.value}
                                        helperText={error?.value}
                                        onChange={changeHandle}
                                        name="value"
                                        value={values.value}
                                        label="Valor"
                                        required />
                                    <ContainerLabel>
                                        <Autocomplete
                                            fullWidth
                                            renderInput={(params) => <InputLabel error={!!error?.category} helperText={error?.category} label="Categoria:" {...params} required />}
                                            options={categories ?? []}
                                            getOptionLabel={(option) => option.description}
                                            value={values.category}
                                            onChange={changeCategoryHandle}
                                            size='small'
                                            disabled={!!defaultCategoryId || disabledForm}
                                        />
                                    </ContainerLabel>
                                </div>
                                {
                                    defaultValue?.type !== EnumProductType.TAXA_DE_SERVICO &&
                                    <div className={styles.column} >
                                        <div className={styles.row} >
                                            <InputLabel
                                                disabled={disabledForm}
                                                onChange={changeHandle}
                                                name="productInformation"
                                                value={values.productInformation}
                                                multiline
                                                // minRows={8.7}
                                                // minRows={currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas ? 5 : 8.7}
                                                minRows={5}
                                                label="Descrição"
                                                placeholder="Ex.: delicioso hámburguer preparado com ingredientes especiais"
                                            />
                                        </div>
                                        <div className={styles.row} >
                                            <Autocomplete
                                                fullWidth
                                                renderInput={(params) => <InputLabel label="Impressão inteligente" {...params} />}
                                                size='small'
                                                options={sectorPrinters ?? []}
                                                getOptionLabel={(option) => option.name}
                                                multiple
                                                value={sectorPrinterValues}
                                                onChange={onChangeSectorPrinter}
                                                disabled={!!defaultCategoryId || disabledForm}
                                                placeholder="Ex.: Epson TM-T20"
                                                noOptionsText="Sem impressora cadastrada"
                                            />
                                            <IconButton
                                                className={styles.redirectPrint}
                                                onClick={() => window.open("/private/pdv/impressoras-inteligentes", '_blank')}
                                            >
                                                <OpenInNewOutlined fontSize="small" />
                                            </IconButton>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            defaultValue?.type === EnumProductType.TAXA_DE_SERVICO &&
                            (
                                <span className={styles.switchHelp}>
                                    <SwitchLabel disabled={disabledForm} checked={values.blockPrint} onChange={(e) => changeHandleSwitch('blockPrint', e)} label="Bloquear impressão" />
                                    <Tooltip title="Bloquear impressão na POS" text="Bloquear impressão de produto apenas no POS. Não afetando outras configurações para impressão remota" />
                                </span>
                            )
                        }
                        {defaultValue?.type !== EnumProductType.TAXA_DE_SERVICO && (hideAdvancedProperty
                            ? (
                                <Button
                                    variant="text"
                                    disabled={disabledForm}
                                    style={{ width: 200, padding: 4 }}
                                    color="secondary"
                                    endIcon={<KeyboardArrowDownRounded />}
                                    onClick={() => setHideAdvancedProperty(false)}
                                >
                                    Informações avançadas
                                </Button>
                            )
                            :
                            <>
                                <div className={styles.grid} style={{ gridTemplateColumns: currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas && values.type !== EnumProductType.KILO ? "1fr 2fr" : "" }}>
                                    {!isConfigProduct && <ContainerLabel >
                                        <Autocomplete
                                            fullWidth
                                            onChange={onChangeStore}
                                            disabled={disabledForm}
                                            value={values.store}
                                            renderInput={(params) => <InputLabel label="Loja"  {...params} />}
                                            options={stores ?? []}
                                            getOptionLabel={(option) => option.name}
                                            size='small'
                                        />
                                    </ContainerLabel>}
                                    {!isConfigProduct && <ContainerLabel>
                                        <InputLabel disabled={disabledForm} onChange={changeHandle} name="type" value={values.type} select label="Tipo de produto" >
                                            <MenuItem value={EnumProductType.PRODUTO}>Produto</MenuItem>
                                            <MenuItem value={EnumProductType.KILO}>Pesado</MenuItem>
                                            <MenuItem value={EnumProductType.VARIAVEL}>Variável</MenuItem>
                                            <MenuItem value={EnumProductType.INGRESSO_AVULSO}> Ingresso Avulso</MenuItem>
                                            <MenuItem value={EnumProductType.INGRESSO_COM_NOME_NA_LISTA}>Ingresso com nome na lista</MenuItem>
                                            <MenuItem value={EnumProductType.DOACAO}>Doação</MenuItem>
                                        </InputLabel>
                                    </ContainerLabel>}
                                    {currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && values.type !== EnumProductType.KILO && (
                                        <ContainerLabel >
                                            <Autocomplete
                                                fullWidth
                                                disabled={disabledForm}
                                                onChange={onChangePrinter}
                                                value={values.defaultPrinter}
                                                renderInput={(params) => <InputLabel label="Impressora padrão"  {...params} />}
                                                options={printers ?? []}
                                                getOptionLabel={(option) => option.name}
                                                size='small'
                                            />
                                        </ContainerLabel>
                                    )}
                                    {
                                        values.type === EnumProductType.KILO && (
                                            <ContainerLabel>
                                                <InputLabel disabled={disabledForm} onChange={changeHandle} name="tare" value={values.tare} label="Tara (g)" />
                                            </ContainerLabel>
                                        )
                                    }
                                </div>
                                <div className={styles.grid}>
                                    <InputLabel disabled={disabledForm} onChange={changeHandle} name="internalCode" value={values.internalCode} label="Código interno" />
                                    <InputLabel disabled={disabledForm} onChange={changeHandle} name="integrationCode" value={values.integrationCode} label="Código de integração" />
                                    <InputLabel disabled={disabledForm} onChange={changeHandle} name="barcode" value={values.barcode} label="Código de barras" type={width > 600 ? "number" : "tel"} />
                                </div>
                                <div className={styles.grid} >
                                    <InputLabel disabled={disabledForm} onChange={changeHandle} name="expireTime" value={values.expireTime} label="Tempo de expiração (min)" type={width > 600 ? "number" : "tel"} />
                                    <InputLabel disabled={disabledForm} onChange={changeHandle} name="maxQuantityPerUser" value={values.maxQuantityPerUser} label="Quantidade máxima de venda por usuário" />
                                    <InputLabel disabled={disabledForm} onChange={changeHandle} name="genderRestriction" value={values.genderRestriction} select label="Restrição por sexo" >
                                        <MenuItem value={EnumGender.unknow}  >Não informado</MenuItem>
                                        <MenuItem value={EnumGender.male}  >Masculino</MenuItem>
                                        <MenuItem value={EnumGender.female}  >Feminino</MenuItem>
                                    </InputLabel>
                                    {
                                        (values.type === EnumProductType.INGRESSO_AVULSO || values.type === EnumProductType.INGRESSO_COM_NOME_NA_LISTA) && <ContainerLabel >
                                            <Autocomplete
                                                fullWidth
                                                onChange={onChangeEvent}
                                                value={values.event}
                                                renderInput={(params) => <InputLabel label="Evento"  {...params} />}
                                                options={events ?? []}
                                                getOptionLabel={(option) => option.name}
                                                size='small'
                                            />
                                        </ContainerLabel>
                                    }
                                </div>
                                {
                                    currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && values.type === EnumProductType.KILO && (
                                    <div className={styles.grid}>
                                        <ContainerLabel>
                                            <Autocomplete
                                                fullWidth
                                                disabled={disabledForm}
                                                onChange={onChangePrinter}
                                                value={values.defaultPrinter}
                                                renderInput={(params) => <InputLabel label="Impressora padrão"  {...params} />}
                                                options={printers ?? []}
                                                getOptionLabel={(option) => option.name}
                                                size='small'
                                                />
                                        </ContainerLabel>
                                    </div>)
                                }

                                <div className={styles.grid} >
                                    <div className={styles.stockControl}>
                                        <SwitchLabel disabled={disabledForm} checked={values.stockControl} label="Gestão de estoque" onChange={(e) => changeHandleSwitch('stockControl', e)} />
                                        {values.stockControl &&
                                            <InputLabel disabled={disabledForm} onChange={changeHandle} name="reservedQuantity" value={values.reservedQuantity} label="Quantidade reservada" type='number' />
                                        }
                                    </div>
                                    <SwitchLabel disabled={disabledForm} checked={values.serviceFeeExemption} onChange={(e) => changeHandleSwitch('serviceFeeExemption', e)} label="Isenção de taxa de serviço" />
                                    <SwitchLabel disabled={disabledForm} checked={values.adultHood} onChange={(e) => changeHandleSwitch('adultHood', e)} label="Exclusivo para maiores de 18 anos" />
                                </div>
                                <div className={styles.grid}>
                                    <span className={styles.switchHelp}>
                                        <SwitchLabel disabled={disabledForm} checked={values.blockPrint} onChange={(e) => changeHandleSwitch('blockPrint', e)} label="Bloquear impressão" />
                                        <Tooltip title="Bloquear impressão na POS" text="Bloquear impressão de produto apenas no POS. Não afetando outras configurações para impressão remota" />
                                    </span>
                                    <SwitchLabel disabled={disabledForm} checked={values.dontShowInKDS} onChange={(e) => changeHandleSwitch('dontShowInKDS', e)} label="Não exibir produto no KDS" />
                                    <SwitchLabel disabled={disabledForm} checked={values.featured} onChange={(e) => changeHandleSwitch('featured', e)} label="Destacar produto" />
                                </div>
                                <Button
                                    variant="text"
                                    style={{ width: "185px", padding: 0 }}
                                    color="secondary"
                                    endIcon={<KeyboardArrowUpRounded />}
                                    onClick={() => setHideAdvancedProperty(true)}
                                    disabled={disabledForm}
                                >
                                    Informações avançadas
                                </Button>
                            </>)
                        }
                        <Separator />
                    </div>
                    {!isConfigProduct && <div className={styles.column}>
                        <div className={styles.column} style={{ gap: "6px" }}>
                            <SubTitle step={2}> Em quais <b>cardápios</b> você quer usar esse produto?</SubTitle>
                            <span style={{ color: "rgba(162, 162, 162, 1)" }}>Selecione os cardápios em que você deseja que seu produto esteja disponível para os seus clientes.</span>
                        </div>
                        <div className={`${styles.row} ${styles.inputWidth}`} >
                            <ContainerLabel>
                                <Autocomplete
                                    fullWidth

                                    renderInput={(params) => <InputLabel error={!!error?.catalog} helperText={error?.catalog} label="Cardápio" {...params} />}
                                    options={catalogs ?? []}
                                    // getOptionSelected={(opt, value) => opt.id === value.id}
                                    getOptionLabel={(option) => option.description}
                                    multiple
                                    value={values.catalogs ?? []}
                                    onChange={changeCatalogHandle}
                                    size='small'
                                    disabled={(!!defaultCatalogId || disabledForm) && !defaultValue}
                                />
                            </ContainerLabel>
                        </div>
                        <Separator />
                    </div>}
                    {!isConfigProduct && <div className={styles.column}>
                        <div className={styles.column} style={{ gap: "6px" }}>
                            <SubTitle step={3}> Conte se seu produto possui <b>complementos:</b></SubTitle>
                            <span style={{ color: "rgba(162, 162, 162, 1)" }}>Complementos são itens que podem ser selecionados junto com o produto.</span>
                        </div>
                        <ComplementFragment
                            disabled={disabledForm}
                            values={[...values.complements]}
                            onChange={onChangeComplement}
                            disabledComplementGroup={disabledComplementGroup}
                        />
                        <Separator />
                    </div>}
                    <div className={styles.column}>
                        <SubTitle step={isConfigProduct ? 2 : 4}> Insira as <b>informações fiscais:</b></SubTitle>
                        <div className={`${styles.row} ${styles.rowMobile} ${styles.taxSettings}`} >
                            <FormItemContainer label={'NCM'} width="100%" maxWidth={500}>
                                <InputMasked mask="9999.99.99" error={!!error?.ncm} helperText={error?.ncm} disabled={disabledForm} onChange={changeHandle} name="ncm" value={values.ncm} type={width > 600 ? "number" : "tel"} />
                            </FormItemContainer>
                            <FormItemContainer label={'CEST'} width="100%" maxWidth={500}>
                                <InputMasked mask="99.999.99" disabled={disabledForm} onChange={changeHandle} name="cest" value={values.cest} type={width > 600 ? "number" : "tel"} />
                            </FormItemContainer>
                            <FormItemContainer label={'CFOP'} width="100%" maxWidth={500}>
                                <InputMasked mask="9.999" error={!!error?.cfop} helperText={error?.cfop} disabled={disabledForm} onChange={changeHandle} name="cfop" value={values.cfop} type={width > 600 ? "number" : "tel"} />
                            </FormItemContainer>
                        </div>
                        <Separator />
                    </div>
                    {!isConfigProduct && <div className={styles.column}>
                        <div className={styles.column} style={{ gap: "6px" }}>
                            <SubTitle step={5} > Conte se seu produto possui <b>observações:</b></SubTitle>
                            <span style={{ color: "rgba(162, 162, 162, 1)" }}>Essas são informações que podem ser marcadas junto com o produto, como opção por gelo, açúcar ou guardanapos.</span>
                        </div>
                        <ObservationForm
                            disabled={disabledForm}
                            values={values.observations ?? []}
                            onChange={changeObservationHandle}
                            onChangeGeneralObservation={onChangeGeneralObservation}
                            productGeneralObservation={values.productGeneralObservation}
                        />
                        <Separator />
                    </div>}
                    <ButtonsForm
                        onSubmit={submitHandle}
                        submitLabel='Salvar'
                        cancelLabel='Voltar'
                        onCancel={onCancel}
                        width="160px"
                    ></ButtonsForm>
                </div>
            </div >
    )
}
export default ProductForm




