import React, { FC, useEffect } from 'react'
import { IRankingCustomers } from './interfaces/IRankingCustomers';
import RankingList from '../rakingList/RankingList';
import { moneyMaskNumber } from 'services/utils/Money';
import { IParamsUseRankingCustomers } from '../../hooks/UseRankingCustomers';

export interface IRankingCustomersProps {
    getRankingCustomers: (params?: IParamsUseRankingCustomers) => Promise<IRankingCustomers | undefined>
    rankingCustomers: IRankingCustomers | undefined
    isLoading: boolean | undefined
}

const RankingCustomers: FC<IRankingCustomersProps> = ({ getRankingCustomers, isLoading, rankingCustomers }) => {

    useEffect(() => {
        getRankingCustomers();
    }, [getRankingCustomers])

    return (
        <div>
            <RankingList
                // keyProp="id"
                list={rankingCustomers?.topCustomers}
                isLoading={isLoading}
                colunms={{
                    customerName: { title: "Nome" },
                    paidValue: { title: "Pago", formatValue: (value) => moneyMaskNumber(value) },
                    consumptionValue: { title: "Consumo", formatValue: (value) => moneyMaskNumber(value) },
                }}
            />
        </div>
    )
}
export default RankingCustomers