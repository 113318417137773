import { KeyboardArrowRightRounded } from "@material-ui/icons";
import styles from "./TransactionItem.module.scss";
import { ITransactionsRecords } from "modules/transactionReport/domain/dto/IGetTransactionsResponse";
import { format } from "date-fns";

interface ITransactionItem {
  item: ITransactionsRecords;
  handleFindPayment: (type: number) => string;
  setOpenDetailsSidesheet: React.Dispatch<React.SetStateAction<string>>;
  hasDetailAccess: boolean;
}

const TransactionItem = ({
  item,
  handleFindPayment,
  setOpenDetailsSidesheet,
  hasDetailAccess,
}: ITransactionItem) => {
  return (
    <div id={styles.TransactionItem}>
      <span>{item.TransactionDate ? format(new Date(item.TransactionDate + "Z"), "dd/MM/yyyy' - 'HH:mm") : "-"}</span>
      <span>{item.Equipment ?? "-"}</span>
      <span>{item.Serial ?? "-"}</span>
      <span>{item.FinalCard ?? "-"}</span>
      <span>{item.PaymentCardFlagDescription ?? "-"}</span>
      <span>{handleFindPayment(item.PaymentType)}</span>
      <span>{item.PercentFee ? `${item.PercentFee}%` : "-"}</span>
      <span>{item.FeeValue ? `R$ ${item.FeeValue.toString().replace(".", ",")}` : "-"}</span>
      <span>{item.ReceiptDate ? format(new Date(item.ReceiptDate), "dd/MM/yyyy") : "-"}</span>
      <span>{item.Anticipation === "0" ? "Não" : "Sim"}</span>
      <span>{item.GrossValue ? `R$ ${item.GrossValue.toString().replace(".", ",")}` : "-"}</span>
      <span>{item.NetValue ? `R$ ${item.NetValue.toString().replace(".", ",")}` : "-"}</span>
      <span className={styles.status}>{item.StatusDescription}</span>
      <div 
        className={styles.icon} 
        onClick={() => hasDetailAccess ? setOpenDetailsSidesheet(item.Id) : null}
        style={hasDetailAccess ? {} : { cursor: "default", color: "#5F5F5F" }}
      >
        <KeyboardArrowRightRounded />
      </div>
    </div>
  );
};
export default TransactionItem;
