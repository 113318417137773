import React from "react";
import styles from "./ProductionMovements.module.scss";
import { CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { moneyMaskNumber } from "services/utils/Money";
import { IPosPaid } from "../../interfaces/IPosPaid";
import { ICashless } from "../../interfaces/ICashless";
import { ITicket } from "../../interfaces/ITicket";

interface IProductionMovementsProps {
  posPaidData: IPosPaid | undefined;
  cashlessData: ICashless | undefined
  ticketData: ITicket | undefined
  isLoading: boolean | undefined;
}

const ProductionMovements: React.FC<IProductionMovementsProps> = ({
  posPaidData,
  cashlessData,
  ticketData,
  isLoading,
}) => {
  const {isMobile} = useUi();

  return (
    <div id={styles.ProductionMovements}>
      <div className={styles.topTitle}>
        <p>Produção</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile }>
        {(posPaidData?.totalClosedProduction ?? 0) > 0 && (
          <>
            <div>
              <h5>Pós-pago</h5>
              <p>Comandas {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <strong>{posPaidData?.totalClosedProduction ?? 0}</strong>
              )}</p>
              
              <p>Consumo {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <strong>{moneyMaskNumber(posPaidData?.productionConsumption)}</strong>
              )}</p>
              <p>Taxa de Serviço: <strong>{moneyMaskNumber(posPaidData?.productionServiceFeeValue)}</strong></p>
            </div>
          </>
        )}
        {(cashlessData?.totalRechargeProduction ?? 0) > 0 && (
          <>
            {
              !isMobile && (posPaidData?.totalClosedProduction ?? 0) > 0 &&
                <div className={styles.separator}></div>
            }
            <div>
              <h5>Cashless</h5>
              <p>Cartões: <strong>{(cashlessData?.totalRechargeProduction ?? 0) + (cashlessData?.totalConsumedProduction ?? 0)}</strong></p>
              <p>Recarga: <strong>{cashlessData?.totalRechargeProduction ?? 0} ({moneyMaskNumber(cashlessData?.productionRechargeValue)})</strong></p>
              <p>Consumo: <strong>{cashlessData?.totalConsumedProduction ?? 0} ({moneyMaskNumber(cashlessData?.productionConsumedValue)})</strong></p>
              <p>Taxa de Serviço: <strong>{moneyMaskNumber(cashlessData?.productionServiceFeeValue)}</strong></p>
            </div>
          </>
        )}
        {(ticketData?.totalProduction ?? 0) > 0 && (
          <>
            {
              !isMobile && 
              ((posPaidData?.totalClosedProduction ?? 0) > 0 || (cashlessData?.totalRechargeProduction ?? 0) > 0) &&
                <div className={styles.separator}></div>
            }
            <div>
              <h5>Ficha</h5>
              <p>Pedidos: <strong>{ticketData?.totalProduction ?? 0}</strong></p>
              <p>Pagamento: <strong>{moneyMaskNumber(ticketData?.productionPaymentValue ?? 0)}</strong></p>
              <p>Consumo: <strong>{moneyMaskNumber(ticketData?.productionConsumedValue ?? 0)}</strong></p>
              <p>Taxa de Serviço: <strong>{moneyMaskNumber(ticketData?.productionServiceFeeValue ?? 0)}</strong></p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductionMovements;
